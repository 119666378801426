import { isUndefined } from "lodash";
import i18n from "@/i18n";
import { PacketService } from "@/util/packet.service";
import { useAction } from "@/util/store-helper";
import { SERVICE_TYPES, SERVICE_SUB_TYPES, PACKET_TYPES } from "./service.type";
import PRINT_TYPES from "./print.type";

export const CONSIGN_STATES = {
  REQUESTED: "requested",
  CONSIGNED: "consigned",
  NOT_CONSIGNED: "not_consigned",
  TIMED_OUT: "timed_out"
};

export const RETURN_SHIPMENT_STATES = {
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed"
};

export const ERRORS = {
  CANNOT_BE_CANCELLED: "cannot_be_cancelled"
};

const getDefaultState = () => {
  return {
    packet: {},
    serviceType: null,
    serviceSubType: null,
    readyToPick: false,
    readyForUndelivery: false,
    paidByCard: false,
    paidInCash: false,
    paidWithDevice: false,
    sender: null,
    packets: [],
    toggleSelectionClicked: false,
    returnShipmentId: null,
    eshopName: null,
    packetReceived: false,
    packetBarcode: null,
    consignment: {},
    scannedBarcode: null,
    searchCode: null,
    printables: []
  };
};

const state = getDefaultState();

const getReceiveAutoPrints = state => {
  switch (state.serviceSubType) {
    case SERVICE_SUB_TYPES.B2C_CONSIGN:
      return state.packet.service_type === PACKET_TYPES.MK2C
        ? [PRINT_TYPES.LABEL]
        : [];
    case SERVICE_SUB_TYPES.RETURN_PACKET_CONSIGN:
      return [PRINT_TYPES.CONSIGNMENT_RECEIPT];
    case SERVICE_SUB_TYPES.EXISTING_CLAIM_CONSIGN:
      return [PRINT_TYPES.RETURN_LABEL];
    default:
      return [];
  }
};

const getters = {
  autoPrints: state => {
    switch (state.serviceType) {
      case SERVICE_TYPES.DELIVERY:
        return [PRINT_TYPES.DELIVERY_RECEIPT];
      case SERVICE_TYPES.C2C_CONSIGN:
        return [PRINT_TYPES.LABEL];
      case SERVICE_TYPES.RECEIVE:
        return getReceiveAutoPrints(state);
      case SERVICE_TYPES.RECLAMATION_ASSISTANT:
        return [PRINT_TYPES.CONSIGNMENT_RECEIPT, PRINT_TYPES.RETURN_LABEL];
      default:
        return [];
    }
  },

  consignRequested: state => {
    return state.consignment?.state === CONSIGN_STATES.REQUESTED;
  },

  consignNotConsigned: state => {
    return state.consignment?.state === CONSIGN_STATES.NOT_CONSIGNED;
  },

  packetHasUnpaidCod: state => {
    return state.packet?.cod?.amount_cents > 0 && !state.packet?.cod?.paid;
  }
};

const actions = {
  // SEARCH
  async searchPassword({ commit, dispatch }, password) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PacketService.postPasswordSearch(password);
      const {
        packet,
        service_type,
        service_sub_type,
        printables
      } = response.data;

      // Ensure default values for card and cash payments
      commit("setPaidInCash", false);
      commit("setPaidByCard", false);

      commit("setPacket", packet);
      commit("setPrintables", printables || []);
      commit("setServiceType", service_type || null);
      commit("setServiceSubType", service_sub_type || null);
      commit("setSender", packet?.sender_name || null);
      commit("setEshopName", packet?.eshop_name || null);
      commit("setPacketBarcode", packet?.barcode || null);
      commit("refreshReadyToPick");

      return response;
    });
  },

  // DELIVERY
  async deliverPacket({ commit, dispatch }, data) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await PacketService.postPacketDelivery(data);
    });
  },

  async undeliverPacket({ commit, dispatch }, params) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await PacketService.deletePacketDelivery(params);
    });
  },

  // RECEIVE
  async receivePacket({ commit, dispatch }, data) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PacketService.postPacketReceive(data);

      commit("setPacketReceived", true);
      commit("setPacketBarcode", data.barcode);

      return response;
    });
  },

  async receiveC2CPacket({ commit, dispatch }, data) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PacketService.postC2CConsign(data);
      const consignment = response.data;

      dispatch("handleConsignState", { consignment });

      return response;
    });
  },

  async getC2CConsignDetail({ commit, dispatch }) {
    const { handleRequest } = useAction({ commit, dispatch });
    const { id } = state.consignment;

    const finished = await handleRequest(async () => {
      const response = await PacketService.getC2CConsignDetail(id);
      const consignment = response.data;

      return dispatch("handleConsignState", {
        consignment,
        readyForErrors: true
      });
    }, false);

    // If the request fails, we need to return finished true
    // so that the loading and interval can stop.
    return isUndefined(finished) || finished;
  },

  handleConsignState(
    { commit, dispatch },
    { consignment, readyForErrors = false }
  ) {
    let finished = true;

    commit("setConsignment", consignment);

    switch (consignment?.state) {
      case CONSIGN_STATES.REQUESTED:
        finished = false;
        break;

      case CONSIGN_STATES.CONSIGNED:
        commit("setPacketReceived", true);
        commit("setPacketBarcode", consignment?.packet_barcode);
        break;

      case CONSIGN_STATES.NOT_CONSIGNED:
      case CONSIGN_STATES.TIMED_OUT:
        readyForErrors && dispatch("setConsignError");
        break;
    }

    return finished;
  },

  setConsignError({ dispatch }) {
    const { handleError } = useAction({ dispatch });

    handleError({
      code: state.consignment?.failure_reason,
      text: state.consignment?.failure_reason_text
    });
  },

  // RETURNS
  async getReturnPackets({ commit, dispatch }, password) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PacketService.getReturnsForClient(password);
      const { items: packets } = response.data;

      commit("setPackets", packets);

      return response;
    });
  },

  async deliverReturnPackets({ commit, dispatch }, passwordAndBarcodes) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await PacketService.postReturnsForClientDeliver(
        passwordAndBarcodes
      );
    });
  },

  // RETURN SHIPMENT
  async createReturnShipment({ commit, dispatch }, passwordAndCustomer) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PacketService.postReturnShipment(
        passwordAndCustomer
      );
      const { id } = response.data;

      commit("setReturnShipmentId", id);

      return response;
    });
  },

  async getReturnShipmentDetail({ commit, dispatch }) {
    const { handleRequest, handleError } = useAction({ commit, dispatch });
    const id = state.returnShipmentId;

    const finished = await handleRequest(async () => {
      const response = await PacketService.getReturnShipmentDetail(id);
      const { state: status, packet_barcode, printables } = response.data;
      let finished = false;

      switch (status) {
        case RETURN_SHIPMENT_STATES.COMPLETED:
          // State can be completed but barcode might not be present.
          // We need to keep the interval running until we get the barcode.
          if (!packet_barcode) break;

          finished = true;
          commit("setPacketBarcode", packet_barcode);
          commit("setPrintables", printables);
          break;

        case RETURN_SHIPMENT_STATES.FAILED:
          finished = true;
          handleError(i18n.t("errorMessages.something-has-broken"));
          break;
      }

      return finished;
    }, false);

    // If the request fails, we need to return finished true
    // so that the loading and interval can stop.
    return isUndefined(finished) || finished;
  },

  // PRINTS

  async print({ commit, dispatch }, typeAndBarcode) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await PacketService.postPrint(typeAndBarcode);
    });
  }
};

const mutations = {
  setPacket(state, packet) {
    state.packet = packet;
    this.commit("clearErrors");
  },

  setServiceType(state, serviceType) {
    state.serviceType = serviceType;
    this.commit("clearErrors");
  },

  setServiceSubType(state, serviceSubType) {
    state.serviceSubType = serviceSubType;
    this.commit("clearErrors");
  },

  setSender(state, sender) {
    state.sender = sender;
    this.commit("clearErrors");
  },

  setPackets(state, packets) {
    state.packets = packets;
    this.commit("clearErrors");
  },

  setEshopName(state, eshopName) {
    state.eshopName = eshopName;
    this.commit("clearErrors");
  },

  refreshReadyToPick(state) {
    state.readyToPick =
      state.packet.cod.paid ||
      state.packet.cod.amount_cents == 0 ||
      state.paidbyCard == true ||
      state.paidInCash == true;
    this.commit("clearErrors");
  },

  setPacketReceived(state, packetReceived) {
    state.packetReceived = packetReceived;
    this.commit("clearErrors");
  },

  setConsignment(state, consignment) {
    state.consignment = consignment;
    this.commit("clearErrors");
  },

  setPacketPaid(state, isPaid) {
    state.packet.cod.paid = isPaid;
    this.commit("clearErrors");
  },

  setPaidWithDevice(state, isPaid) {
    state.paidWithDevice = isPaid;
    this.commit("clearErrors");
  },

  setPacketBarcode(state, packetBarcode) {
    state.packetBarcode = packetBarcode;
    this.commit("clearErrors");
  },

  setPaidByCard(state, isPaid) {
    state.paidByCard = isPaid;
    this.commit("clearErrors");
  },

  setPaidInCash(state, paidInCash) {
    state.paidInCash = paidInCash;
    this.commit("clearErrors");
  },

  setReturnShipmentId(state, returnShipmentId) {
    state.returnShipmentId = returnShipmentId;
    this.commit("clearErrors");
  },

  setReadyForUndelivery(state, isReady) {
    state.readyForUndelivery = isReady;
  },

  setToggleSelectionClicked(state, toggleSelectionClicked) {
    state.toggleSelectionClicked = toggleSelectionClicked;
  },

  setScannedBarcode(state, scannedBarcode) {
    state.scannedBarcode = scannedBarcode;
  },

  setSearchCode(state, searchCode) {
    state.searchCode = searchCode;
  },

  setPrintables(state, printables) {
    state.printables = printables;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
